const closeAll = Accordion => {
    const Items = Accordion.querySelectorAll('.list__item');
    Items.forEach(Item => {
        Item.classList.remove('list__item--expanded');
        Item.querySelector('.item__content').style.height = null;
    })
}
const open = Item => {
    Item.classList.add('list__item--expanded');
    Item.querySelector('.item__content').style.height = `${Item.querySelector('.item__content').scrollHeight}px`;
    setTimeout(() => {
        Item.querySelector('.item__title').scrollIntoView({
            behavior: 'smooth'
        });
    },200)
}
const isOpen = Item => {
    return Item.classList.contains('list__item--expanded');
}
document.addEventListener('DOMContentLoaded', function () {
    const Accordions = document.querySelectorAll('.accordion');
    Accordions.forEach(Accordion => {
        const Items = Accordion.querySelectorAll('.list__item');
        Items.forEach(Item => {
            Item.querySelector('.item__title').addEventListener('click', event => {
                if (!isOpen(Item)) {
                    closeAll(Accordion)
                    open(Item);
                } else {
                    closeAll(Accordion)
                }
            })
        })
    })
})
