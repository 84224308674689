document.addEventListener('DOMContentLoaded', function () {

  // Базовые переменные для мобильного меню
  const burgerBtnWrapper = document.querySelector('.burger-btn__wrapper')
  const burgerBtn = document.querySelector('.burger-btn')
  const mainMenuWrapper = document.querySelector('.header__top')
  const mainMenuContent = document.querySelector('.header__top-content')

  // Обработка логики для поведение мобильного меню
  burgerBtnWrapper.addEventListener('click', function () {
    burgerBtn.classList.toggle('burger-btn--active')
    if (burgerBtn.classList.contains('burger-btn--active')) {
      mainMenuWrapper.classList.add('header__top--active')
      document.body.style.overflowY = 'hidden'
      setTimeout(function () {
        mainMenuContent.style.display = 'flex'
      }, 200)
      
    } else {
      mainMenuWrapper.classList.remove('header__top--active')
      document.body.style.overflowY = 'visible'
      mainMenuContent.style.display = 'none'
    }
  })

  // Проверяем ширину экрана и если она больше, чем ширина, при которой меню переходит в мобильный режим, устанавливаем значения по умолчанию, для элементов, в которых могли произойти изменения
  window.addEventListener('resize', function () {
    if (document.body.clientWidth >= 940) {
      burgerBtn.classList.remove('burger-btn--active')
      mainMenuWrapper.classList.remove('header__top--active')
      document.body.style.overflowY = 'visible'
      mainMenuContent.style.display = 'flex'
    }
  })
})
