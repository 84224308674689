;document.addEventListener('DOMContentLoaded', function () {

    const Modals = document.querySelectorAll('.modal');
    // console.log(Modals);
    if (Modals) {
        // Добавляем прослушку событий на кнопки (которые открывают модальные окна)
        const ModalActionBtns = document.querySelectorAll('button[data-targetModal]');
        for (let i = 0; i < ModalActionBtns.length; i++) {
            ModalActionBtns[i].addEventListener('click', function () {
                const targetModal = document.querySelector(`#${this.getAttribute('data-targetModal')}`);
                // console.log(this.getAttribute('data-targetModal'))
                // console.log(targetModal)
                targetModal.classList.add('modal--active');
                document.querySelector('body').style.overflowY = 'hidden';
            })
        }
        // Добавляем прослушку событий на кнопки закрыть модальное окно
        const CloseModalBtns = document.querySelectorAll('.modal__close-btn');
        for (let i = 0; i < CloseModalBtns.length; i++) {
            CloseModalBtns[i].addEventListener('click', function () {
                // console.log(this.closest('.modal'))
                this.closest('.modal').classList.remove('modal--active');
                document.querySelector('body').style.overflowY = 'auto';
            })
        }
    }
});
