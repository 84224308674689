// window.addEventListener('DOMContentLoaded', function () {
//   document.querySelector('.map').addEventListener('mouseenter',function () {
//     document.querySelector('.overflow-layer').style.opacity = 1
//     setTimeout(function () {
//       document.querySelector('.overflow-layer').style.opacity = 0
//       setTimeout(function () {
//         document.querySelector('.overflow-layer').style.display = 'none'
//       }, 350)
//     }, 10)
//   })
//   document.querySelector('.map').addEventListener('mouseleave',function () {
//     document.querySelector('.overflow-layer').style.display = 'block'
//     document.querySelector('.overflow-layer').style.opacity = 0
//     setTimeout(function () {
//       document.querySelector('.overflow-layer').style.opacity = 1
//     }, 10)
//   })
// })

window.addEventListener('DOMContentLoaded', function () {
  const map = document.querySelector('.map')
  if (map) {
    map.addEventListener('mouseenter',function () {
      document.querySelector('.overflow-layer').style.display = 'none'
    })
    map.addEventListener('mouseleave',function () {
      document.querySelector('.overflow-layer').style.display = 'block'
    })
  }

})